import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import "firebase/firestore";

import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "../msal.json";
const loginRequest = {
  scopes: ["User.Read", "Files.ReadWrite.All"],
};

const client = new PublicClientApplication(msalConfig);

require("isomorphic-fetch");
const { Client } = require("@microsoft/microsoft-graph-client");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    mapMedia: [],
    accessToken: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setMapMedia(state, mapMedia) {
      state.mapMedia = mapMedia;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.user != null;
    },
  },
  actions: {
    login(context, payload) {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password);
    },
    logout() {
      firebase.auth().signOut();
    },
    async updateMedia(context) {
      if (context.state.accessToken) {
        var updateMedia = firebase.functions().httpsCallable("updateMedia", {
          timeout: 540000,
        });
        const response = await updateMedia({
          accessToken: context.state.accessToken,
        });
        console.log(response);
      }
    },
    async loginMSAL(context) {
      let response = null;
      const currentAccounts = client.getAllAccounts();
      if (currentAccounts.length === 1) {
        loginRequest.account = currentAccounts[0];
        response = await client.acquireTokenSilent(loginRequest);
      } else {
        response = await client.loginPopup(loginRequest);
      }
      context.commit("setAccessToken", response?.accessToken);
    },
    async loadMapMedia(context) {
      if (context.getters.isLoggedIn) {
        const uid = context.state.user.uid;
        const mediaDoc = await firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("data")
          .doc("media")
          .get();
        if (mediaDoc.exists) {
          const url = await firebase
            .storage()
            .ref(`users/${uid}/data/media/mapMedia.json`)
            .getDownloadURL();
          const mapMedia = await (await fetch(url)).json();
          context.commit("setMapMedia", mapMedia);
        }
      }
    },
    async getMediaItem(context, id) {
      if (context.state.accessToken) {
        const graph = Client.init({
          authProvider: (done) => {
            done(null, context.state.accessToken);
          },
        });
        const item = await graph
          .api("/me/drive/items/" + id)
          .expand("thumbnails")
          .get();
        return item;
      }
      return null;
    },
  },
  modules: {},
});
