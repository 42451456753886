<template>
  <b-container fluid class="d-flex flex-column p-0 h-100">
    <b-navbar toggleable="md" type="dark" variant="info" class="tl-nav">
      <b-navbar-brand class="tl-title text-primary">
        <i class="tl-icon mr-2"></i><span class="tl-title-bold">travellog</span
        ><span> media</span>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <login></login>
      </b-navbar-nav>
    </b-navbar>
    <div class="w-100 h-100 position-absolute">
      <l-map
        :center="center"
        :zoom="zoom"
        :options="{ zoomControl: false }"
        class="w-100 h-100"
        ref="map"
      >
        <l-control-layers
          position="topright"
          :collapsed="true"
        ></l-control-layers>
        <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
        />
      </l-map>
    </div>
  </b-container>
</template>

<script>
import L from "leaflet";
import "leaflet";
import { LMap, LTileLayer, LControlLayers } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "../plugins/Leaflet.Photo.css";
import "../plugins/map.css";

import "leaflet.markercluster/dist/leaflet.markercluster";
import "../plugins/Leaflet.Photo.js";

import Login from "../components/Login";

export default {
  name: "Home",
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    login: Login,
  },
  data() {
    return {
      zoom: 7,
      center: [47.482352, 13.928678],
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: false,
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
        },
        {
          name: "Mapbox Light",
          visible: true,
          url:
            "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9uYXNnYWlzd2lua2xlciIsImEiOiJja2F6cTVkd20wMGxmMnNvM2IwY2Jmc3Q1In0.RpGAwZ4eqsBoPzK6pu6-AA",
          attribution:
            '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          tileSize: 512,
          zoomOffset: -1,
        },
        {
          name: "Mapbox Dark",
          visible: false,
          url:
            "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9uYXNnYWlzd2lua2xlciIsImEiOiJja2F6cTVkd20wMGxmMnNvM2IwY2Jmc3Q1In0.RpGAwZ4eqsBoPzK6pu6-AA",
          attribution:
            '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          tileSize: 512,
          zoomOffset: -1,
        },
        {
          name: "Mapbox Satellite",
          visible: false,
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9uYXNnYWlzd2lua2xlciIsImEiOiJja2F6cTVkd20wMGxmMnNvM2IwY2Jmc3Q1In0.RpGAwZ4eqsBoPzK6pu6-AA",
          attribution:
            '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          tileSize: 512,
          zoomOffset: -1,
        },
        {
          name: "Esri",
          visible: false,
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          attribution: '&copy; <a href="http://www.esri.com/">Esri</a>',
        },
      ],
    };
  },
  watch: {
    "$store.state.mapMedia": function (mapMedia) {
      const vm = this;
      if (mapMedia.length > 0) {
        this.$nextTick(function () {
          var photoLayer = L.photo.cluster().on("click", async function (evt) {
            let photo = evt.layer.photo;
            const mediaItem = await vm.$store.dispatch(
              "getMediaItem",
              photo.id
            );
            if (mediaItem) {
              let template = `<img src="${mediaItem.thumbnails[0].large.url}"/></img>`;

              if (
                mediaItem.video &&
                !!document
                  .createElement("video")
                  .canPlayType("video/mp4; codecs=avc1.42E01E,mp4a.40.2")
              ) {
                template = `<video autoplay controls poster="${mediaItem.thumbnails[0].large.url}"><source src="${mediaItem["@microsoft.graph.downloadUrl"]}" type="video/mp4"/></video>`;
              }

              template += `<p><a href="${mediaItem.webUrl}" target='_blank'>${mediaItem.name}</a></p>`;

              evt.layer
                .bindPopup(L.Util.template(template, photo), {
                  className: "leaflet-popup-photo",
                  minWidth: 400,
                })
                .openPopup();
            }
          });
          photoLayer
            .add(
              mapMedia.map((item) => {
                return {
                  lat: item[1],
                  lng: item[2],
                  thumbnail: `https://l.travellog.io/msThumb.php?id=${encodeURIComponent(
                    item[0]
                  )}&accessToken=${encodeURIComponent(
                    vm.$store.state.accessToken
                  )}`,
                  id: item[0],
                };
              })
            )
            .addTo(this.$refs.map.mapObject);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.tl-title {
  font-family: "PT Sans", sans-serif;
  font-style: italic;
}

.tl-title-bold {
  font-weight: bold;
  letter-spacing: 0.05em;
}

.tl-icon {
  display: inline-block;
  background-image: url("https://travellog.io/img/logo-white.svg");
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 1em;
  background-repeat: no-repeat;
  filter: invert(53%) sepia(84%) saturate(6456%) hue-rotate(183deg)
    brightness(98%) contrast(101%);
}

::v-deep .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-size: 16px 16px;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23404040' d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'%3E%3C/path%3E%3C/svg%3E");
}

::v-deep .leaflet-top {
  &.leaflet-right {
    top: 49px;
  }
}

.tl-nav {
  z-index: 1000;
  backdrop-filter: blur(5px) saturate(180%);
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 6px 0px #00000066;
  padding: 0.3rem 0.9rem;
  background-color: #ffffffcc !important;
}
</style>
