<template>
  <div>
    <b-button
      variant="link"
      class="text-decoration-none text-primary"
      v-b-modal.modal-login
    >
      <i class="fas fa-user"></i>
    </b-button>
    <b-modal id="modal-login" :title="$t('login')" hide-footer>
      <template v-if="!isLoggedIn">
        <b-form @submit.stop.prevent="login">
          <b-form-group :label="$t('email')" label-for="input-email">
            <b-form-input
              id="input-email"
              v-model="form.email"
              type="email"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('password')" label-for="input-password">
            <b-form-input
              id="input-password"
              v-model="form.password"
              type="password"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">{{ $t("login") }}</b-button>
        </b-form>
      </template>
      <template v-else>
        <b-button @click="updateMedia">Update Media</b-button>
        <b-button @click="logout" class="ml-2">{{ $t("logout") }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.form);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    updateMedia() {
      this.$store.dispatch("updateMedia");
    },
  },
};
</script>

<style></style>
