import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import firebase from "firebase/app";
import "firebase/auth";

import firebaseConfig from "./firebase.json";
import i18n from "./i18n";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

firebase.initializeApp(firebaseConfig);
//firebase.functions().useEmulator("localhost", 5001);

// let authPromise = new Promise((resolve, reject) => {
//   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//     unsubscribe();
//     resolve(user);
//   }, reject);
// });

firebase.auth().onAuthStateChanged(async (user) => {
  // authPromise = user;
  store.commit("setUser", user);
  if (user) {
    await store.dispatch("loginMSAL");
    store.dispatch("loadMapMedia");
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
